<template>
  <div :class="shortText ? 'short-welcome-container' : 'welcome-container'">
    <div class="welcome-container-title">Welcome to dib8</div>
    <div class="welcome-container-text">
      <span
        >The first audio based, topic centered, community shaped social network
        that aims to remove unwanted noise from conversations. <br />
        <br
      /></span>
      <span v-if="!shortText">
        Dib8 is an audio platform that allows people to start or participate in
        conversations on subjects and express positive, negative or neutral
        views. Dib8 does not have any current presence in the consumer market,
        so our first priority will be to launch a basic version of this
        application that will allow us to test the functionality of our business
        model by measuring engagement levels and demographic trends.
        <br /><br
      /></span>
      While the web version allows you to listen to specific bits of a
      conversation, through our mobile apps you can participate in any
      conversation.
    </div>
    <div class="welcome-container-actions">
      <img
        class="welcome-action"
        src="../assets/astore.svg"
        alt=""
        @click="
          window.location.href =
            'https://apps.apple.com/ro/app/dib8/id1572242341'
        "
      />
      <img
        class="welcome-action"
        src="../assets/gplay.svg"
        alt=""
        @click="
          window.location.href =
            'https://play.google.com/store/apps/details?id=com.dib8.app'
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeCard",
  props: {
    shortText: {
      type: Boolean,
    },
  },
  data() {
    return {
      window: window,
    };
  },
};
</script>

<style scoped>
.welcome-container {
  border-radius: 1.25rem;
  background: rgba(229, 243, 247);
  min-height: 46.813rem;
  max-width: 31.125rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 3rem;
}

.short-welcome-container {
  border-radius: 1.25rem;
  background: rgba(229, 243, 247, 0.81);
  min-height: 31.813rem;
  max-width: 31.125rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 3rem;
}

.welcome-container-title {
  font-family: "Nunito";
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.welcome-container-text {
  font-family: "Nunito";
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.welcome-container-actions {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .welcome-action {
    cursor: pointer;
    width: 11.25rem;
  }
}

@media (max-width: 600px) {
  .welcome-action {
    cursor: pointer;
    width: 9.375rem;
  }
}
</style>